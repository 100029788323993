import React, { Component } from "react";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import menuNames from "../menuNames.json";

class NavBar extends Component {
  testOnSelect = key => {
    alert(`selected ${key}`);
  };

  handleSelect = key => {
    switch (key) {
      case menuNames.menu1.submenu1a:
      case menuNames.menu1.submenu1b:
      case menuNames.menu1.submenu1c:
      case menuNames.menu1.submenu1d:
      case menuNames.menu2.submenu2a:
      case menuNames.menu2.submenu2b:
      case menuNames.menu2.submenu2c:
      case menuNames.menu2.submenu2d:
      case menuNames.menu3.submenu3a:
      case menuNames.menu3.submenu3b:
      case menuNames.menu3.submenu3c:
      case menuNames.menu3.submenu3d:
      case menuNames.menu4:
      // case menuNames.menu5:
      case "inicio":
        this.props.onChangeView(key);
        break;
      default:
        break;
    }
  };

  // handle selct por si los menus son personalizables o_O
  _handleSelect = (key, array) => {
    for (let index = 0; index < array.length; index++) {
      if (key === array[index]) {
        this.props.onChangeView(key);
      }
    }
  };

  render() {
    return (
      <Navbar
        className={this.props.clase}
        // inverse
        collapseOnSelect
        onSelect={this.handleSelect}
        fixedTop
      >
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            <NavItem eventKey={"inicio"} href="#">
              Inicio
            </NavItem>
            <NavDropdown
              eventKey={1}
              title={menuNames.menu1.nombre}
              id="basic-nav-dropdown"
            >
              <MenuItem eventKey={menuNames.menu1.submenu1a}>
                {menuNames.menu1.submenu1a}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu1.submenu1b}>
                {menuNames.menu1.submenu1b}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu1.submenu1c}>
                {menuNames.menu1.submenu1c}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu1.submenu1d}>
                {menuNames.menu1.submenu1d}
              </MenuItem>
            </NavDropdown>
            <NavDropdown
              eventKey={2}
              title={menuNames.menu2.nombre}
              id="basic-nav-dropdown"
            >
              <MenuItem eventKey={menuNames.menu2.submenu2a}>
                {menuNames.menu2.submenu2a}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu2.submenu2b}>
                {menuNames.menu2.submenu2b}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu2.submenu2c}>
                {menuNames.menu2.submenu2c}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu2.submenu2d}>
                {menuNames.menu2.submenu2d}
              </MenuItem>
            </NavDropdown>
            <NavDropdown
              eventKey={3}
              title={menuNames.menu3.nombre}
              id="basic-nav-dropdown"
            >
              <MenuItem eventKey={menuNames.menu3.submenu3a}>
                {menuNames.menu3.submenu3a}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu3.submenu3b}>
                {menuNames.menu3.submenu3b}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu3.submenu3c}>
                {menuNames.menu3.submenu3c}
              </MenuItem>
              <MenuItem eventKey={menuNames.menu3.submenu3d}>
                {menuNames.menu3.submenu3d}
              </MenuItem>
            </NavDropdown>
            <NavItem eventKey={menuNames.menu4}>{menuNames.menu4}</NavItem>
            {/*<NavItem eventKey={menuNames.menu5}>{menuNames.menu5}</NavItem>*/}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
